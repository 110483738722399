<template>
    <v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="viewForm"
            v-model="valid"          
        >
        <v-row>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.hub_name"
                        :counter="30"
                        :rules="[rules.required]"
                        :label="$store.getters.getTextMap().hub_name"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.hub_model"
                        :counter="10"
                        :label="$store.getters.getTextMap().hub_model"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.hub_type"
                        :counter="10"
                        :label="$store.getters.getTextMap().hub_type"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().submitbtnColor"
                       
                        @click="onSubmit"
                    >
                      {{ $store.getters.getTextMap().submit}}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                        :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel}}
                    </v-btn>
                </v-col>
        </v-row>
    </v-form>
    </v-container>
            
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'UpdateHubDetails',
    props:['hub'],
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            form:{
                modbus_latency:0
            },
            rules:FormRules.rules
        }
    },
    mounted(){
        // console.log(this.view,'view_id')
        this.init()

    },
    methods:{
        init(){
            // console.log(this.view)
            if(this.hub && this.hub.hub_id){
                this.form.hub_name=this.hub.hub_name
                this.form.hub_type=this.hub.hub_type
                this.form.hub_model=this.hub.hub_model
        }
        },
        onSubmit(){
            this.$refs.viewForm.validate()
            if(this.valid){
                 this.loading=true
                let d=Object.assign({}, this.form)
                // console.log(d)
                d['hub_id']=this.hub.hub_id
                axios.post(this.$store.state.api+'updateHubDetails',d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshHubs')
                            this.$emit('update')
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit('close')
        }
    },
    watch:{
        hub:{
            handler(){
                
                this.init()
            },
            deep:true
        },
    }
}
</script>