<template>

    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card flat :dark="$store.getters.getColorPalette().isDark">
        <v-card-title>{{$store.getters.getTextMap().diagnostics_notification_groups}} <v-progress-circular
  indeterminate
  v-if="loading"
  color="primary"
></v-progress-circular>
<v-spacer></v-spacer>

<span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
  <v-icon
    small
    class="mr-2"
    v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
    @click="edit=!edit"
  >
    mdi-pencil
  </v-icon>
</span>
</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle >
    
    <v-row v-if="loading">
        <v-col>
            <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
        </v-col>
    </v-row>
    
    <v-row v-if="$store.state.user && this.edit && $store.getters.getAccessByLevels(['engineeraccess']) ">
        <v-col cols="1">
            <span><v-btn   fab :dark="$store.getters.getColorPalette().isDark" @click="addedit=!addedit" color="blue" >
    <v-icon>mdi-plus</v-icon>
  </v-btn></span>
        </v-col>
        <v-col v-if="addedit">
            <v-form
ref="addForm"
v-model="valid">
            <v-row>
                
                <v-col >

<v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
<v-autocomplete
                    v-if="!loading"
            v-model="form.group_ids"
            :items="notificationGroups"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            multiple
            :label="$store.getters.getTextMap().notification_group"
            item-text="label"
                    item-value="value"
                    :rules=[rules.required]
          ></v-autocomplete>
                </v-col>
                
                
                <v-col>
                    <v-btn
      v-if="!loading"
      :color="$store.getters.getColorPalette().submitbtnColor"
        text
        @click="addTupleToGroup"
      >
      {{ $store.getters.getTextMap().add}}
      </v-btn>
                </v-col>
            </v-row>
            </v-form>
        </v-col>
        
    </v-row>
    <v-row>
        <v-col>
            <v-simple-table >
<template v-slot:default>
  <thead>
    <tr>
      <th class="text-left">
        <strong>{{ $store.getters.getTextMap().name}}</strong>
      </th>
      <th class="text-left">
        <strong>{{ $store.getters.getTextMap().group_id}}</strong>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      v-for="item in tuples"
      :key="item.group_id"
    >
      <td>{{ item.name }}</td>
      <td>{{ item.group_id }}</td>
      <td v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        <DeleteConfirmation  v-on:confirm="deleteTupleFromGroup(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
            <v-icon small color="red" >mdi-delete</v-icon>
        </DeleteConfirmation>
    </td>
    </tr>
  </tbody>
</template>
</v-simple-table>
        </v-col>
    </v-row>
    </v-card-subtitle>
        </v-card>
    </v-container>

</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import FormRules from '@/utillities/FormRules'
//import AddTupleToList from '@/components/crud_components/AddTupleToList'
export default {
name:'NotificationGroup',
components:{
    InfoAlert,
    //AddTupleToList
    DeleteConfirmation
},
mounted(){
    this.getTuples()
},
data(){
    return {
        showedit:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        addedit:false,
        valid:false,
        edit: false,
        tuples:[],
        form:{
          address:null,
          name:null,
        },
        stringRules: [
    v => !!v || 'Required',
    v => /\S+/.test(v) || 'Required',
    v => (v && v.length <= 30 && v.length > 0) || 'Cannot be more than 30 characters',
  ],
  rules:FormRules.rules,
  bitRules:[
      v => !!v || 'Required',
      v => /[0-9]+$/.test(v) || 'It should be a number',
      v => (v && !isNaN(v))|| 'It should be a valid number',
      v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
  ],
  emailRules:FormRules.emailRules,
  nameRules:FormRules.nameRules
    }
},

computed:{
    notificationGroups(){
        let op=[]
        if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length>0){
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
        }

        return op
    }
    
    
},

methods:{
  onClose(){
        this.edit=false,
        this.$emit('close')
        },
    getTuples(){
        this.loading=true
        axios.get(this.$store.state.api+'diagnosticNotificationGroups',{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.tuples=response.data.data
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
    },
    
    deleteTupleFromGroup(tuple){

        axios.post(this.$store.state.api+'removeNotificationGroupFromDiagnostics',tuple,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The Group has been deleted'
                        this.showDismissibleAlert=true
                        this.tuples=this.tuples.filter((t)=>{return t.group_id!=tuple.group_id})
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })

    },
    addTupleToGroup(){
        this.$refs.addForm.validate()
        if(this.valid){
            this.loading=true

      axios.post(this.$store.state.api+'addNotificationGroupToDiagnostics',this.form,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status=='success'){
                        //this.$store.dispatch('refreshListData')
                        this.loading=false
                        this.info='The Group has been added'
                        this.showDismissibleAlert=true
                        this.getTuples()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true

                })
    }
    },
  cancel(){
      this.showedit=false
    },
    confirm(){
      this.showedit=false
    }    
},
watch:{
    
}

}
</script>