<template>
<v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
    ref="logForm"
    v-model="valid"
    
  >
    <v-row>
        <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.date"
            :label="$store.getters.getTextMap().date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
           :dark="$store.getters.getColorPalette().isDark" 
          ></v-text-field>
        </template>
        <v-date-picker
        :dark="$store.getters.getColorPalette().isDark" 
          v-model="form.date"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
            </v-col>



            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-btn
                v-if="!loading"
                ref="submitBtn"
          :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().fetch_connectivity_logs}}
          </v-btn>
          
            </v-col>
            
    </v-row>
    </v-form>
    <v-row>
                <v-col cols="12">
                    <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$store.getters.getTextMap().search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
                    <v-data-table
      :headers="headers"
      :items="logs"
      item-key="timestamp"
      :search="search"
      
      :loading="loading"
    loading-text="Loading... Please wait"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Hub Internet connectivity Logs</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
            </v-toolbar>
          </template>
                    </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            

</v-container>
    
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'HubInternetConnectivityTable',
    props:['hub_id'],
    components:{
        InfoAlert
    },
    mounted(){
        this.form.date=moment().format('YYYY-MM-DD')
        
        //let btn=this.$refs.submitBtn
        //btn.click()
        this.onSubmit()
    },
    data(){
        return {
            search:'',
             valid:false,
                menu:false,
            showDismissibleAlert:false,
            loading:false,
            info:'',
            expanded: [],
            logs:[],
            form:{
                date:null
            },
            headers: [
          {
            text: 'Time',
            align: 'start',
            filterable: false,
            value: 'time',
             class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Internet Active', value: 'active', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Version', value: 'version', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Upload Speed (Mbit/sec)', value: 'upload_speed', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
            ],
        
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    computed:{
        fromTimeStamp(){
        if(this.form.date){
          
            return Number(moment(this.form.date,'YYYY-MM-DD').format("X"));
          
        }else{
          return null
        }
      },
      toTimeStamp(){
        if(this.form.date){
          
            let dt=this.form.date.toString()
            
                dt+=' 23:59'
            return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
          
        }else{
          return null
        }
      },
    },
    methods: {
        onSubmit(){
            this.$refs.logForm.validate()
            if(this.valid){
              this.loading=true
              //let fromTimeStamp=this.fromTimeStamp
              //let toTimeStamp=this.toTimeStamp
              let payload={
                  hub_id:this.hub_id,
      from_timestamp:this.fromTimeStamp,
      to_timestamp:this.toTimeStamp
    }
    
    //console.log(fromTimeStamp)
    axios.post(this.$store.state.api+'getHubConnectivityDataBetweenTimestamp',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      console.log("Hub data received")
      //console.log(response.data.monthlyData)
      //context.state.monthlyData=response.data.monthlyData
//console.log(response.data.data)
//console.log('something')


if(response.data.status==='success'){
let data=[]
for (let i of response.data.data){
  // console.log(i['timestamp'])
  i['time']=moment(i['timestamp'],'X').format('HH:mm:ss')
  // console.log(i['time'])
    data.push(i)
  
}
this.logs=data
      //console.log(response.data.data)
      
      if(this.logs.length==0){
          this.info = "No data to show";
            this.showDismissibleAlert = true;
      }
    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      //this.loading=false
    }
      this.loading=false

    })
    .catch(err=>{
    this.showDismissibleAlert=true
            this.info=err
            this.loading=false
            })

    
    
    
    
            }
        }
    
    },
}
</script>