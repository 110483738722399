<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false"  />
        <v-card  :dark="$store.getters.getColorPalette().isDark"  >
            <v-card-title>
          {{ $store.getters.getTextMap().update_your_OPC_config}}
         </v-card-title>
            <v-form ref="opcForm" v-model="valid">
                <v-row>
                    <v-col>
                        <v-skeleton-loader
                            v-if="loading"
                        class="mx-auto"
                        type="text"
                        ></v-skeleton-loader>
                        <v-select
                            v-if="!loading"
                            v-model="form.opc_client"
                            :items="boolOptions"
                            :label="$store.getters.getTextMap().OPC_client_mode"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col v-if="form.opc_client">
                        <v-text-field
                          v-model="form.opc_url" 
                          :rules="[rules.required,rules.noSpace,rules.size(70)]"
                          :counter="70"
                          :loading="loading"
                          :label="$store.getters.getTextMap().OPC_server_URL"
                          
                          required
                        ></v-text-field>
                    </v-col>
                    </v-row>
          <v-row v-if="form.opc_client">
                    <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.opc_meta.userAuthMode"
          :items="userAuthModeOptions"
          :label="$store.getters.getTextMap().user_auth"
          outlined          
          item-text="label"
          item-value="value"
          required
          :dark="$store.getters.getColorPalette().isDark"
            :rules="[rules.required]"
        ></v-select>
            </v-col>
                    <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.opc_meta.security_mode"
          :items="securityModeOptions"
          :label="$store.getters.getTextMap().security_mode"
          outlined          
          item-text="label"
          item-value="value"
          required
          :dark="$store.getters.getColorPalette().isDark"
            :rules="[rules.required]"
        ></v-select>
            </v-col>
                    <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.opc_meta.mode"
          :items="opcModeOptions"
          :label="$store.getters.getTextMap().mode"
          outlined          
          item-text="label"
          item-value="value"
          required
          :dark="$store.getters.getColorPalette().isDark"
            :rules="[rules.required]"
        ></v-select>
            </v-col>
                    <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.opc_meta.security_policy"
          :items="securityPolicyOptions"
          :label="$store.getters.getTextMap().security_policy"
          outlined          
          item-text="label"
          item-value="value"
          required
          :dark="$store.getters.getColorPalette().isDark"
            :rules="[rules.required]"
        ></v-select>
            </v-col>
                    
                </v-row>
                <v-row v-if="form.opc_client && form.opc_meta && form.opc_meta.userAuthMode=='user'">
                  <v-col >
                        <v-text-field
                          v-model="form.opc_meta.username" 
                          :rules="[rules.required]"
                          :counter="30"
                          :loading="loading"
                          :label="$store.getters.getTextMap().user_name"
                          :dark="$store.getters.getColorPalette().isDark"
                          required
                        ></v-text-field>
                    </v-col>
                  <v-col >
                    <v-text-field
            v-model="form.opc_meta.password"
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions>
<v-row justify="end">
    <v-col><v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
          
            v-if="!loading"

            @click="onSubmit"
          >
           {{ $store.getters.getTextMap().submit}}
          </v-btn>
          
            
        
          <v-progress-circular
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-circular>
          </v-col>
<v-col><v-btn
            color="red darken-1"
          
            v-if="!loading"

            @click="$emit('close')"
          >
          {{ $store.getters.getTextMap().cancel}}
          </v-btn>
          
            
        
          <v-progress-circular
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-circular>
          </v-col>
</v-row>
                </v-card-actions>
            </v-form>

        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'UpdateHubOPC',
    props:['hub'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.hub){
            this.form={
                hub_id:this.hub.hub_id,
                opc_client:this.hub.opc_client,
                opc_url:this.hub.opc_url,
                opc_meta:{
                  "security_policy_uri": "urn:opcua:python:server",
                  "certificate_path": "certificate.pem",
                "private_key_path": "key.pem",
                }
                
            }
            if(this.hub.opc_meta){
              this.form['opc_meta']=this.hub.opc_meta
            }
        }
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            showPass:false,
            form:{
              opc_meta:{

                "url": "opc.tcp://192.168.0.210:53530/OPCUA/SimulationServer",
                "userAuthMode":"user",
                "security_mode": "none",
                "security_policy_uri": "urn:opcua:python:server",
                "security_policy": "Basic256Sha256",
                "username": "enture",
                "password": "enture@1",
                "certificate_path": "certificate.pem",
                "private_key_path": "key.pem",
                "mode":"SignAndEncrypt"

              }
            },
            boolOptions:[
                {label:'On',value:true},
                {label:"Off",value:false}
            ],
            userAuthModeOptions:[
              { label:'Username & password',value:'user'},
              { label:'Anonymous', value:'anonymous'}
            ],
            securityModeOptions:[
              {label:'None',value:'none'},
              {label:'Certificate',value:'certificate'},
            ],
            opcModeOptions:[
              {label:'Sign',value:'Sign'},
              {label:'Sign And Encrypt',value:'SignAndEncrypt'},
            ],
            securityPolicyOptions:[
                {label:'AES128_Sha256_RsaOaep',value:'AES128_Sha256_RsaOaep'},
                {label:'AES256_Sha256_RsaOaep',value:'AES256_Sha256_RsaPss'},
                {label:'Basic128Rsa15',value:'Basic128Rsa15'},
                {label:'Basic256',value:'Basic256'},
                {label:'Basic256Sha256',value:'Basic256Sha256'}
            ],
            rules:FormRules.rules,
            objectRules:[
                v => v!=null || 'Required',
            ],
            urlRules: [
        v => !!v || 'URL is required',
        v => /^\S+$/.test(v) || 'No Spaces allowed',
        v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
      ],

        }
    },
    methods:{
        onSubmit(){
            this.$refs.opcForm.validate()
            if(this.valid){
            this.loading = true;
            this.form.opc_meta.url=this.form.opc_url
      axios
        .post(this.$store.state.api + "updateHubOPC", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshHubs");
  
            this.info = 'Settings Updated';
            this.showDismissibleAlert = true;
            //this.onReset()
            
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        }
    }
}
</script>