<template>
    <v-container class="" fluid>
        <v-row>
            <v-col>
                <strong    :style="{color:$store.getters.getColorPalette().accentCode}">
                    {{$store.getters.getTextMap().modbus_gateways}}
                </strong >
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="3" xs="12" sm="12" md="4" xl="3" v-bind:key="mGate.mgate_id" v-for="mGate in mGates">
            <MGate :mGate="mGate"/>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MGate from '@/components/items/MGate'
export default {
    name:'MGateList',
    components:{
        MGate
    },
    computed:{
        mGates(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.getters.getMGates()
        }
    },
}
</script>
<style scoped>
.container{
    margin-top: -5%;
}
</style>