<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false"  />
        <v-card :dark="$store.getters.getColorPalette().isDark"  class="px-1">
            <v-row>
           <v-col align-self="center">{{ $store.getters.getTextMap().Update_your_Edge_Hub_restart_Schedule }}</v-col><v-col align-self="center" align="right"><v-btn
            icon
            
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn></v-col>
         </v-row>
         <v-divider></v-divider>
            <v-form ref="hubForm" v-model="valid">
        <v-row align="center">
            <v-col align-self="center">
                 <v-skeleton-loader
                v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-checkbox
          v-if="!loading"
      v-model="form.restart"
      :label="$store.getters.getTextMap().auto_restart"
    ></v-checkbox>
            </v-col>
            <v-col v-if="form.restart" align-self="center" >
                           <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu
v-if="!loading"
        ref="menutime"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            :label="$store.getters.getTextMap().time_24Hrs"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu"
          v-model="time"
          full-width
          format="24hr"
          @click:minute="$refs.menutime.save(time)"
        ></v-time-picker>
      </v-menu>
            </v-col>
            <v-col  v-if="form.restart" align-self="center">
              <v-autocomplete
                               :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="form.day"
                                    :items="dayOptions"
                                    :label="$store.getters.getTextMap().day_of_week"
                                              
                                    item-text="label"
                                    item-value="value"
                                                                        
                                ></v-autocomplete>
            </v-col>
            
            <v-col>
                <v-btn small  :color="$store.getters.getColorPalette().submitbtnColor"  :dark="$store.getters.getColorPalette().isDark"  @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
            </v-col>
        </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import EntureStaticLists from '@/utillities/EntureStaticLists'
import axios from 'axios'
export default {
    name:'UpdateHubRestartSchedule',
    props:['hub'],
    components:{
        InfoAlert
    },
    mounted(){

    },
    data(){
        return {
            menu:false,
            valid:false,
            loading:false,
            time:null,
            timeDialog:false,
            info:'',
            showDismissibleAlert:false,
            form:{
                restart:false
            },
            objectRules:FormRules.objectRules,
            dayOptions:EntureStaticLists.dayOptions,
        }
    },
    methods:{
        close(){
            this.$emit('close')
        },
        onSubmit(){
            this.$refs.hubForm.validate()
            if(this.valid){
              this.loading=true
              this.form['hub_id']=this.hub.hub_id
            if(this.form.restart && this.time){
                this.form['hour']=this.time.split(':')[0]
                this.form['minute']=this.time.split(':')[1]
                
            }
            axios.post(this.$store.state.api+'updateHubRestartSchedule',this.form,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response=>{


                if(response.data.status==='success'){

                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                    this.close()
                }else{
                    this.info = response.data.msg;
                    //this.info = d;
                            this.showDismissibleAlert = true;
                    //console.log(response.data.msg)
                    
                }
                    this.loading=false

            })
            .catch(err=>{
                    this.showDismissibleAlert=true
                            this.info=err
                            this.loading=false
            })

                
            
        }
        }
    }

}
</script>