<template>
    <v-container class="" fluid>
        <v-row>
            <v-col>
                <strong    :style="{color:$store.getters.getColorPalette().accentCode}">
                    {{$store.getters.getTextMap().enture_hubs}}
                </strong>
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="3"  xs="12" sm="12" md="3" xl="3" v-bind:key="hub.hub_id" v-for="hub in hubs">
            <Hub :hub="hub"/>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Hub from '@/components/items/Hub'
export default {
    name:'HubList',
    components:{
        Hub
    },
    computed:{
        hubs(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.getters.getHubs()
        }
    },
}
</script>