<template>
  <div class="text-center">
    <!-- class="setng my-1" -->
    <v-btn
    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
      @click="dialog = !dialog"
    >
     {{$store.getters.getTextMap().update_wifi}} <v-icon
      right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small
      >mdi-wifi
      </v-icon>
    </v-btn><v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
      <UpdateWifi  v-on:close="dialog=false"/>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import UpdateWifi from '@/components/UpdateWifi'
export default {
    name:'UpdateWifiBottomSheet',
    components:{
      UpdateWifi  
    },
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        
    }
}
</script>
<style scoped>
.setng{
  margin-right: 62px;
  border:3px solid;
  border-radius:20px;
  
}


.v-dialog__content{
      transform:scale(0.75)
    }

</style>