<template>
    <!-- <v-container fluid :style="{'background-color':$store.getters.getColorPalette().background2ColorCode,'border-radius': '15px ','border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor}" class="mx-1 my-1">
    <v-card  class="text-capitalize  lighten-2" flat dark color="blue-grey darken-3" router to="/devices"> -->
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark"  class="mx-1 my-1 text-capitalize  " flat >

        <v-container fluid >
        <v-card-title >{{$store.getters.getTextMap().connected_communication_devices}}</v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle >
        <v-row>
            <v-col >
                <router-link to="/devices" class="link">
                <Strong>{{$store.getters.getTextMap().mqtt_devices}}
: &nbsp; </Strong>
                <strong class="text-h5">{{$store.state.mqttDevices.length}}</strong>
                </router-link>
            </v-col>
            <v-col>
                 <router-link to="/devices" class="link">
                <Strong>{{$store.getters.getTextMap().modbus_devices}}:  &nbsp; </Strong>
                <strong class="text-h5">{{$store.state.devices.length}}</strong>
                  </router-link>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                 <router-link  to="/devices" class="link" >
                <Strong>{{$store.getters.getTextMap().total_no_of_params}}
:  &nbsp;</Strong>
                <strong class="text-h5"><small>{{$store.state.parameters.length}}</small></strong>
                 </router-link>
            </v-col>
            <v-col>
                <Strong>{{$store.getters.getTextMap().edge_devices}}
:  &nbsp;</Strong>
                <strong class="text-h5">{{$store.state.hubs.length}}</strong>
            </v-col>
        </v-row>
                <v-row>
            <v-col>
                <router-link  to="/machines" class="link">
                <Strong>{{$store.getters.getTextMap().machines_configured}}: &nbsp; </Strong>
                <strong class="text-h5"><small>{{$store.state.dashboardMachines.length}}</small></strong>
                </router-link>
            </v-col>
            <v-col>
                   <router-link  to="/reports" class="link">
                <Strong>{{$store.getters.getTextMap().reports_configured}}
:  &nbsp;</Strong>
                <strong class="text-h5">{{ totalReports }}</strong>
                 </router-link>
            </v-col>
        </v-row>
             <v-row>
            <v-col>
                 <router-link  to="/views" class="link">
                <Strong>{{$store.getters.getTextMap().views_configured}}:  &nbsp;</Strong>
                <strong class="text-h5"><small>{{$store.state.views.length}}</small></strong>
                  </router-link>
            </v-col>
        </v-row>
        </v-card-subtitle>
    </v-container>
</v-card>
</template>

<script>
export default {
    name:'EntureConnectedPlacard',

computed: {
    totalReports() {
      const {
        secReports,
        // granularSECReports,
        secTimeFrameReports,
        consolidatedReports,
        dynamicExcelReports,
        usageReports,
        energyUsageReports,
        energyReports,
        energyDistributionReports,
        energyBillDistReports
      } = this.$store.state;

      const total = secReports.length +
                    // granularSECReports.length +
                    secTimeFrameReports.length +
                    consolidatedReports.length +
                    dynamicExcelReports.length +
                    usageReports.length +
                    energyUsageReports.length +
                    energyReports.length +
                    energyDistributionReports.length +
                    energyBillDistReports.length;
      return total;
    }
  }
}
</script>
<style scoped>
.link{
  text-decoration: none;
   color: inherit;
}
</style>