<template>
  <div class="">
    <v-card
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().background2ColorCode"
      flat
      class="mt-0 pt-2"
      align="left"
      :style="{
        'border-radius': '5px',
        border:
          '1px solid' + $store.getters.getColorPalette().panelBorderColorCode,
      }"
    >
      <div class="chart-container">
        <apexchart
          ref="chart"
          type="donut"
          :options="chartOptions"
          :series="chartSeries"
          
        ></apexchart>
      </div>
      <v-card-text class="pt-0 text-center">
        <v-divider class="my-2"></v-divider>
        <div class="title font-weight-light mb-2">
          {{$store.getters.getTextMap().types_of_modbus_device_connected}}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
 
<script>
import VueApexCharts from "vue-apexcharts";
 
export default {
  name: "MDeviceTypePercentagePanel",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartSeries: [],
      chartOptions: {
        tooltip: {
          enabled: true,
          theme: false,
          style: {
    color: this.$store.getters.getColorPalette().accentCode,
  },
        },
        chart: {
          width: 380,
          type: "donut",
        },
        colors: [
          "#84FFFF",
          "#69F0AE",
          "#9C27B0",
          "#2196F3",
          "#FFFF00",
          "#1B5E20",
          "#CCFF90",
          "#01579B",
          "#F44336",
        ],
        legend:{
          labels:{
            colors:this.$store.getters.getColorPalette().accentCode
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            startAngle: -90,
            endAngle: 270,
            donut: {
              size:'60%',
              labels: {
                color:this.$store.getters.getColorPalette().accentCode,
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  color:this.$store.getters.getColorPalette().accentCode
                },
                value:{
                show:true,
                color:this.$store.getters.getColorPalette().accentCode
              }
              },
              
            },
          },
        },
        labels: [],
        dataLabels: {
          enabled: true,
          style: {
                colors: [this.$store.getters.getColorPalette().accentCode]
              }
        },
        fill: {
          type: "gradient",
        },
        title: {
          text: "",
        },
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                width: 300,

              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
 
  mounted() {
    this.updateChart();
  },
  computed: {
    dataPoints() {
      return this.$store.getters.getTotalMDeviceTypes();
    },
  },
  methods: {
    updateChart() {
      let { labels, data } = this.dataPoints;
      this.chartOptions.labels.splice(
        0,
        this.chartOptions.labels.length,
        ...labels
      );
      this.chartSeries = data;
      this.$refs.chart.updateSeries(this.chartSeries);
    },
  },
  watch: {
    dataPoints() {
      this.updateChart();
    },
  },
};
</script>

<style scoped>
.chart-container{
  width: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1080px) {
  .chart-container{
    width: 400px;
  }
}

@media (max-width: 960px) {
  .chart-container{
    width: 350px;
  }
}

@media (max-width: 950px) {
  .chart-container{
    width: 50%;
    /* margin: auto; */
  }
}


@media (max-width: 730px) {
  .chart-container{
    width: 100%;
    height: auto;
  }
}


</style>