<template>
    
         <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-form
      ref="mGateForm"
      v-model="valid"
      
    >
        <v-card :dark="$store.getters.getColorPalette().isDark">
          <v-card-title >
         {{$store.getters.getTextMap().add_modbus_TCP_gateway}}
          </v-card-title>
          <v-card-text>
              
                  <v-row >
                      <v-col>
              <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
        v-model="form.name"
        :counter="15"
        :rules="nameRules"
        :label="$store.getters.getTextMap().name"
        required
      ></v-text-field>
                      </v-col>
                      <v-col>
      <v-text-field :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
        v-model="form.ip"
        :counter="16"
        :rules="ipRules"
        :label="$store.getters.getTextMap().IPAddress"
        required
      ></v-text-field>
                      </v-col>
  
                  </v-row>
                  <v-row>
                      
                      <v-col>
                          <v-text-field :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
                          v-model="form.port"
                          :counter="5"
                          :rules="numberRules"
                          :label="$store.getters.getTextMap().port_number"
                          required>
  
                          </v-text-field>
                      </v-col>
                      
                      <v-col>
                          
                          <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.hub_id"
            :items="$store.state.hubs"
            item-text="hub_id"
            item-value="hub_id"
            :label="$store.getters.getTextMap().HubID"
           :rules="idRules"
           required
            
            clearable
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          ></v-select>
                      </v-col>
                      
                  </v-row>
                  <v-row>
                    <v-col>
                          
                          <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.baud_rate"
            :items="options.baudRates"
            item-text="label"
            item-value="value"
            :label="$store.getters.getTextMap().baud_rate"
           
           required
            
            clearable
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          ></v-select>
                      </v-col>
                    <v-col>
                          
                          <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.parity"
            :items="options.parityOptions"
            item-text="label"
            item-value="value"
            :label="$store.getters.getTextMap().parity"
           
           required
            
            clearable
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          ></v-select>
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                          
                          <v-select :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
            v-model="form.stop_bit"
            :items="options.stopBits"
            item-text="label"
            item-value="value"
            :label="$store.getters.getTextMap().stop_bit"
           
           required
            
            clearable
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          ></v-select>
                      </v-col>
                      <v-col>
                          <v-text-field :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().inputtextColor"
        v-model="form.desc"
        :counter="35"
        
        :label="$store.getters.getTextMap().description"
        required
      ></v-text-field>
                      </v-col>
                  </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
              text
              @click="onSubmit"
            >
             {{ $store.getters.getTextMap().create}}
            </v-btn>
            <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
              text
              @click="onReset"
            >
            {{ $store.getters.getTextMap().cancel}}
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-form>

        </v-container>
        </template>
  <script>
  import axios from 'axios'
  import InfoAlert from '@/components/InfoAlert'
  import EntureStaticLists from '@/utillities/EntureStaticLists'
  import FormRules from '@/utillities/FormRules'
  export default {
      name:'CreateMGate',
      components:{
        InfoAlert
      },
      
      data(){
          return {
              dialog:false,
              valid:false,
              loading:false,
              info:'',
              showDismissibleAlert:false,
              options:EntureStaticLists,
              rules:FormRules.rules,
              nameRules: [
          v => !!v || 'Name is required',
          v => /\S+/.test(v) || 'Name is required',
          v => (v && v.length <= 15 && v.length > 0) || 'Name must be less than 15 characters',
        ],
        numberRules:[
            v => !!v || 'Required',
            v => /[0-9]+$/.test(v) || 'It should be a number',
            v => (v && v>0 && v%1==0)|| 'It should be a valid slave ID',
            v => (v && v.length <= 4 && v.length > 0) || 'Name must be less than 4 digits',
        ],
        ipRules:[
            v => !!v || 'Required',
            v => /[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+$/.test(v) || 'Invalid IP',
            v => (v && v.length >= 8 && v.length < 16) || 'IP is wrong',
        ],
        idRules: [
          v => !!v || 'ID is required',
          v => /^\S+$/.test(v) || 'No Space allowed',
          v => (v && v.length <= 6 && v.length > 0) || 'ID must be less than 6 characters',
        ],
          msgRules: [
          v => !!v || 'Description is required',
          v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
        ],
        form:{
                  ip:null,
                  desc:null,
                  name:null,
                  hub_id:null,
                  port:null
                  
              }
          }
      },
      methods:{
          onSubmit(){
              this.$refs.mGateForm.validate()
              if(this.valid){
                this.loading=true
              //evt.preventDefault()
              //this.form.id=(new Date).getTime().toString()
              let d=Object.assign({}, this.form)
              d.mgate_id=d.ip+'-'+d.hub_id
              axios.post(this.$store.state.api+'addMGate',d,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
      }})
      .then(response=>{
        if(response.data.status==='success'){
          this.$store.commit('addMGate',d)
          this.$store.dispatch('refreshMGates')
          console.log("MGate added "+d)
          this.onReset()
              //this.dialog=false
              //this.$emit('close')
      }else{
        //console.log(response.data.msg)
        this.info = response.data.msg;
        //this.info = d;
              this.showDismissibleAlert = true;
        //console.log(response.data.msg)
        this.loading=false
      }})
      .catch(err=>{console.log(err)});
                  //this.$store.dispatch('addDevice', d)
              
              }
          },
          onReset(){
              //evt.preventDefault()
              /*this.form={
                  id:null,
                  msg:null,
                  name:null
              }*/
              this.$refs.mGateForm.reset()
              this.dialog=false
              this.loading=false
              this.$emit('close')
          }
  
      }
  }
  </script>
  <style scoped >
  .setng{
    border:3px solid;
    border-radius:20px;
   
   
  }
  
  
  .v-dialog__content{
        transform:scale(0.75)
      }
  
  </style>
  