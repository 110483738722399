<template>
  <div class="text-center" >
    <!-- class="setng my-1" -->
    <v-btn
    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
      @click="dialog = !dialog"
    >
     {{ $store.getters.getTextMap().add_modbus_gateway}} <v-icon
      right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small
      >mdi-router-network
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center" >
        <span>
          <CreateMGate v-on:close="dialog=false" />
        </span>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import axios from 'axios'
import CreateMGate from '@/components/crud_components/CreateMGate'
export default {
    name:'CreateMGateBottomSheet',
    components:{
      CreateMGate
    },
    
    data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 15 && v.length > 0) || 'Name must be less than 15 characters',
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid slave ID',
          v => (v && v.length <= 4 && v.length > 0) || 'Name must be less than 4 digits',
      ],
      ipRules:[
          v => !!v || 'Required',
          v => /[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+$/.test(v) || 'Invalid IP',
          v => (v && v.length >= 8 && v.length < 16) || 'IP is wrong',
      ],
      idRules: [
        v => !!v || 'ID is required',
        v => /^\S+$/.test(v) || 'No Space allowed',
        v => (v && v.length <= 6 && v.length > 0) || 'ID must be less than 6 characters',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      form:{
                ip:null,
                desc:null,
                name:null,
                hub_id:null,
                port:null
                
            }
        }
    },
    methods:{
        onSubmit(){
            this.$refs.mGateForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            d.mgate_id=d.ip+'-'+d.hub_id
            axios.post(this.$store.state.api+'addMGate',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.commit('addMGate',d)
        this.$store.dispatch('refreshMGates')
        console.log("MGate added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.mGateForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }

    }
}
</script>
<style scoped >
.setng{
  border:3px solid;
  border-radius:20px;
 
 
}


.v-dialog__content{
      transform:scale(0.75)
    }

</style>
