<template>
<span>
    <v-btn
      
      @click="restartDevices"
      v-if="!loading"
      :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined 
         >
    {{ $store.getters.getTextMap().force_update_configs }}
      <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>
        mdi-restart
      </v-icon>
    </v-btn>
    <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    </span>
</template>

<script>
import axios from 'axios';

export default {
    name:'UpdateConfigs',
    data(){
        return {
            loading:false,
            showDismissibleAlert: false,
            info:''
        }
    },
    methods: {
        restartDevices(){
            this.loading=true;
            axios.get(this.$store.state.api+"forceUpdateConfigs",{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
      }}).then(response=>{
                if (response.data.status == "success") {
            
            this.showDismissibleAlert = false;
            //this.$refs.sendOtp.reset();
          } else {
            console.log(response.data.msg)
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            
          }
          this.loading = false;
            }).catch(error=>{
                this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
            })
        }
    },
}
</script>

<style scoped>
.button_name{
  margin-right: 30px;
 
}
</style>