<template>
    <v-container fluid>
        
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
<v-row>
    <v-col>
        <v-form
    ref="orgForm"
    v-model="valid"
    
  >
  <v-card  :color="$store.getters.getColorPalette().cardColor">
        <v-card-title class="headline">
          {{$store.getters.getTextMap().update_wifi}} -   {{$store.getters.getTextMap().HubID}} - {{hub.hub_id}}
        </v-card-title>
        <v-card-text>
            <v-text-field
            v-if="!loading"
      v-model="form.ssid"
      :counter="40"
      :rules="nameRules"
      prepend-icon="mdi-access-point"
      :label="$store.getters.getTextMap().SSID"
      required
    ></v-text-field>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
            v-model="form.psk"
            :rules="passwordRules"
            :label="$store.getters.getTextMap().password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          
          <v-text-field
            v-model="form.confirmpassword"
            :rules="confirmPasswordRules"
            :label="$store.getters.getTextMap().confirm_password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
         
            @click="update"
          >
          {{  $store.getters.getTextMap().submit}}
          </v-btn>
          <v-btn
            color="grey darken-1"
     
            @click="onReset"
          >
          {{  $store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-form>
    </v-col>
</v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'

export default {
    name : 'UpdateHubWifi',
    props:['hub'],
    components:{
      InfoAlert
    },
    data(){
        return {
            form:{
                ssid:null,
                psk:null,
                confirmpassword:null
            },
            showPass:false,
            loading:false,
                info:null,
                showDismissibleAlert:false,
                valid:false,
        nameRules: [
        v => !!v || 'required',
        v => /\S+/.test(v) || 'required',
        v => (v && v.length <= 30 && v.length > 0) || 'Must be less than 30 characters',
      ],
      passwordRules:[
          v=> !!v || 'Required',
          v => (v && v.length >= 8 ) || 'Must be more than 8 characters',
    ],
      confirmPasswordRules:[
          v=> !!v || 'Required',
          v => (v == this.form.psk ) || 'Passwords do not match',
    ],
        };
    },
    methods: {
        update(){
             this.$refs.orgForm.validate()
            if(this.valid){
            this.loading=true
            this.form['hub_id']=this.hub.hub_id
            axios.post(this.$store.state.api+'updateHubWifi',this.form,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
      }})
      .then(response=>{
        if(response.data.status==='success'){
          this.onReset()
          this.loading=false
          
      }else{
        console.log(response.data.msg)
        this.loading=false
        this.info = response.data.msg;
            this.showDismissibleAlert = true;
      }})
      .catch(err=>{
          this.info = err;
            this.showDismissibleAlert = true;
            this.loading=false
      });
            
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            this.$emit('close')
            
        }
    },
}
</script>