<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false"  />
        <v-card  :dark="$store.getters.getColorPalette().isDark" >
            <v-card-title>
           {{ $store.getters.getTextMap().update_your_MQTT_bridge_config }}
         </v-card-title>
         
            <v-form class="pa-3" ref="opcForm" v-model="valid">
                <v-row >
                    <v-col>
                        <v-skeleton-loader
                            v-if="loading"
                        class="mx-auto"
                        type="text"
                        ></v-skeleton-loader>
                        <v-select
                            v-if="!loading"
                            v-model="form.mqtt_bridge"
                            :items="boolOptions"
                            :label="$store.getters.getTextMap().MQTT_bridge_mode"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    
                    
                </v-row>
                <v-row v-if="form.mqtt_bridge">
                    <v-col >
                        <v-skeleton-loader
                            v-if="loading"
                        class="mx-auto"
                        type="text"
                        ></v-skeleton-loader>
                        <v-text-field
            v-model="form.broker_address" 
            :rules="urlRules"
            :counter="60"
            :loading="loading"
            :label="$store.getters.getTextMap().broker_address"
            
            required
          ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                            v-if="loading"
                        class="mx-auto"
                        type="text"
                        ></v-skeleton-loader>
                        <v-text-field
                        v-model="form.port" 
                        :rules="[rules.required,rules.unum,rules.integ,rules.noSpace, rules.size(6)]"
                        :counter="6"
                        :loading="loading"
                        :label="$store.getters.getTextMap().port"
                        
                        required
                    ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="form.mqtt_bridge">
                    <v-col>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                        <v-text-field
                        v-model="form.username" 
                        :rules="[rules.required,rules.noSpace, rules.size(16)]"
                        :counter="16"
                        :loading="loading"
                        :label="$store.getters.getTextMap().user_id"
                        
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                        <v-text-field
                        v-model="form.pass" 
                        
                        :rules="[rules.required]"
                        :label="$store.getters.getTextMap().password"
                        prepend-icon="mdi-key"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
                        @click:append="showPass = !showPass"
                        required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="form.mqtt_bridge">
                    <v-col>
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                        <v-text-field
                        v-model="form.topic_prefix" 
                        :rules="[rules.required]"
                        :label="$store.getters.getTextMap().topic_prefix"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                            v-if="loading"
                        class="mx-auto"
                        type="text"
                        ></v-skeleton-loader>
                        <v-select
                            v-if="!loading"
                            v-model="form.message_direction"
                            :items="messageDirectionOptions"
                            :label="$store.getters.getTextMap().message_direction"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-card-actions>
<v-row justify="end">
    <v-col>
        <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
      
            v-if="!loading"

            @click="onSubmit"
          >
          {{ $store.getters.getTextMap().submit}}
          </v-btn>
          
            
        
          <v-progress-circular
      indeterminate
      color="primary"
      v-if="loading"
    
    ></v-progress-circular>
          </v-col>
<v-col><v-btn
            color="red darken-1"
          
            v-if="!loading"

            @click="$emit('close')"
          >
          {{ $store.getters.getTextMap().cancel}}
          </v-btn>
          
            
        
          <v-progress-circular
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-circular>
          </v-col>
</v-row>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'UpdateHubOPC',
    props:['hub'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.hub){
            this.form={
                // message_direction:'both',
                hub_id:this.hub.hub_id,
                mqtt_bridge:this.hub.mqtt_bridge,
                
                
            }
            this.getMQTTBridgeInfo()
        }
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            bridgeDetails:{},
            form:{},
            showPass:false,
            boolOptions:[
                {label:'On',value:true},
                {label:"Off",value:false}
            ],
            messageDirectionOptions:[
                {label:'In',value:'in'},
                {label:"Out",value:'out'},
                {label:"Both",value:'both'}
            ],
            rules:FormRules.rules,
            objectRules:[
                v => (v!=null || v!=undefined) || 'Required',
            ],
            urlRules: [
        v => !!v || 'URL is required',
        v => /^\S+\.\S+$/.test(v) || 'Should be a valid address',
        v => (v && v.length <= 60 && v.length > 0) || 'Name must be less than 61 characters',
      ],

        }
    },
    methods:{
        getMQTTBridgeInfo(){
            this.loading=true
             axios.post(this.$store.state.api + "getMQTTBridge", {hub_id:this.hub.hub_id},{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            //console.log(response.data.jwt);
            
            
            //this.onReset()
            if(response.data.data){
                this.bridgeDetails=response.data.data
                this.form['broker_address']=this.bridgeDetails['broker_address']
                this.form['port']=this.bridgeDetails['port']
                
                this.form['username']=this.bridgeDetails['username']
                this.form['topic_prefix']=this.bridgeDetails['topic_prefix']
                this.form['message_direction']=this.bridgeDetails['message_direction']
            }
            
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          //this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
        },
        onSubmit(){
            this.$refs.opcForm.validate()
            if(this.valid){
            this.loading = true;
      axios
        .post(this.$store.state.api + "updateHubMQTTBridge", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshHubs");
  
            this.info = 'Settings Updated';
            this.showDismissibleAlert = true;
            //this.onReset()
            
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          //this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        }
    }
}
</script>