<template>
    <div>
        <!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper ma-4"> Control Panel</h1> -->
        <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
            <v-row  v-if="$store.state.user && $store.state.settingMode" >
                <!-- <v-col v-if="$store.state.nodes.length>0">
                    <div class="text-center"><v-btn
                color="blue-grey"
                class="ma-0 white--text"  
                rounded
                @click="show='nodeScheduler'">
            Add Node schedule
      <v-icon
        right
       :dark="$store.getters.getColorPalette().isDark"
      >mdi-timer
      </v-icon>
        </v-btn></div></v-col> -->
        <!-- <v-col :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
            <ViewOrgDetails/>
        </v-col> -->
        <v-col cols="auto" class="d-flex" align="right">
            <CreateMGateBottomSheet v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
            <UpdateWifiBottomSheet class="mx-1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
            <RestartDevices v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['device'])" />
            <UpdateConfigs class="ml-1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
        </v-col>
        <!-- <v-col  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        </v-col>
        
        <v-col  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
        </v-col>
        <v-col align-self="center" align="center" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['device'])" >
            </v-col>
            <v-col align-self="center" align="center" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" >
            </v-col> -->
            </v-row>
            <!-- <v-row >
                <v-col v-if="show=='nodeScheduler'">
            <NodeScheduler  v-on:close="show=''"/>
                </v-col>
                
            </v-row> -->
            <!-- <v-row>
                <v-col>
                    <NodeScheduleList/>
                </v-col>
            </v-row> -->
            <!-- <v-divider></v-divider> -->
            <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
        align="center"
        align-self="center"
        v-if="$store.state.devices.length > 0"
      >
        <MDeviceTypePercentagePanel class="mx-1 my-1" />
      </v-col>



      <v-col
        v-if="$store.state.user && $store.getters.getAccessByLevels(['device'])"
        cols="12"
        sm="12"
        md="6"
      >
        <EntureConnectedPlacard />
      </v-col>
    </v-row>
            
            <v-row  v-if="$store.state.mGates.length>0" class="pt-10">
                <v-col>
                    <MGateList/>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row  v-if="$store.state.hubs.length>0">
                <v-col>
                    <HubList/>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','network'])">
                <v-col>
                <v-expansion-panels hover>
                <v-expansion-panel :style="{'border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-color':$store.getters.getColorPalette().background2ColorCode}"
                        :dark="$store.getters.getColorPalette().isDark"  >
                    <v-expansion-panel-header><h3 :style="{'color':$store.getters.getColorPalette().accentCode}">Wireless Configuration</h3></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card class="mx-auto" outlined :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark" >
                            <v-card-title><span  :color="$store.getters.getColorPalette().background2ColorCode">Default Wireless creds  <v-icon color="red"> mdi-security</v-icon></span></v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                            <span :color="$store.getters.getColorPalette().accentCode"><strong>SSID: </strong></span> &nbsp;  &nbsp; <span class=" grey--text">{{$store.state.organisation?$store.state.organisation.dflt_ssid:'-'}}</span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>  
                                        <v-row>    
                                            <v-col>
                                                <span  :color="$store.getters.getColorPalette().accentCode"> <strong>psk: </strong> </span> &nbsp;  &nbsp; <span class=" grey--text">{{$store.state.organisation?$store.state.organisation.dflt_psk:'-'}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-expansion-panel-content>
                            <v-expansion-panel-content>
                                <v-card class="mx-auto" outlined :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark">
                                    <v-card-title><span :color="$store.getters.getColorPalette().accentCode">Wireless creds  <v-icon color="red"> mdi-security</v-icon></span></v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                            <span  :color="$store.getters.getColorPalette().accentCode">  <strong>SSID: </strong> </span> &nbsp;  &nbsp; <span class=" grey--text">{{$store.state.organisation?$store.state.organisation.ssid:'-'}} </span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text>
                                    <v-row>  
                                        <v-col>
                                        <span :color="$store.getters.getColorPalette().accentCode">   <strong>psk: </strong> </span> &nbsp;  &nbsp; <span class=" grey--text">{{$store.state.organisation?$store.state.organisation.psk:'-'}} </span>
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                </v-card>
                                </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            </v-row>
                       <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
                <v-col>
                    <DiagnosticGroups />
                </v-col>
            </v-row>
                       <!-- <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
                <v-col>
                    <AssignMaintainerToOrg />
                </v-col>
            </v-row>
                       <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
                <v-col>
                    <AssignParentToOrg />
                </v-col>
            </v-row>
            <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])" >
                <v-col>
                    <AddHub />
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>
<script>
// import NodeScheduler from '@/components/NodeScheduler'
// import NodeScheduleList from '@/components/lists/NodeScheduleList'
import MGateList from '@/components/lists/MGateList'
import HubList from '@/components/lists/HubList'
import CreateMGateBottomSheet from '@/components/modals/CreateMGateBottomSheet'
import UpdateWifiBottomSheet from '@/components/modals/UpdateWifiBottomSheet'
// import AssignMaintainerToOrg from '@/components/account_components/AssignMaintainerToOrg'
// import AssignParentToOrg from '@/components/account_components/AssignParentToOrg'
// import AddHub from '@/components/crud_components/AddHub'
// import ViewOrgDetails from '@/components/modals/ViewOrgDetails'
import RestartDevices from '@/components/RestartDevices'
import UpdateConfigs from '@/components/UpdateConfigs'
import DiagnosticGroups from '@/components/crud_components/diagnostics/DiagnosticGroups'
import MDeviceTypePercentagePanel from "@/components/panels/MDeviceTypePercentagePanel";
import EntureConnectedPlacard from "@/components/panels/EntureConnectedPlacard";

export default {
    name:'ControlPanel',
    components:{
        // NodeScheduler,
        // NodeScheduleList,
        MGateList,
        HubList,
        CreateMGateBottomSheet,
        UpdateWifiBottomSheet,
        // ViewOrgDetails,
        // AssignMaintainerToOrg,
        // AssignParentToOrg,
        // AddHub,
        RestartDevices,
        UpdateConfigs,
        DiagnosticGroups,
        MDeviceTypePercentagePanel,
    EntureConnectedPlacard,
    },
    data(){
        return {
            show:''
        }
    },
    mounted() {
        document.title='Control Panel'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>

</style>