<template>
<v-container fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-row>
      <v-col>
        <v-form ref="MGateForm" v-model="valid">
          <v-card
            :color="$store.getters.getColorPalette().backgroundColorCode"
            :dark="$store.getters.getColorPalette().isDark"
          >
            <v-card-title class="headline">
              {{ title }}
            </v-card-title>
            <v-card-text>
              <v-text-field
                :dark="$store.getters.getColorPalette().isDark"
                v-model="form.name"
                :counter="25"
                :rules="nameRules"
                :label="$store.getters.getTextMap().name"
                required
              ></v-text-field>
              <v-col
                v-if="
                  $store.state.user &&
                  $store.getters.getAccessByLevels(['betaaccess'])
                "
              >
                <v-text-field :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
        v-model="form.ip"
        :counter="16"
        :rules="ipRules"
        :label="$store.getters.getTextMap().ip_address"
        required
      ></v-text-field>
              </v-col>
              <v-text-field
                :dark="$store.getters.getColorPalette().isDark"
                v-model="form.port"
                :counter="4"
                :rules="numberRules"
                :label="$store.getters.getTextMap().port"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :color="$store.getters.getColorPalette().submitbtnColor"
                @click="onSubmit"
              >
              {{  $store.getters.getTextMap().submit}}
              </v-btn>
              <v-btn
                :color="$store.getters.getColorPalette().cancelbtnColor"
                :dark="$store.getters.getColorPalette().isDark"
                @click="onReset"
               @close="onReset"
              >
             {{   $store.getters.getTextMap().cancel}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import InfoAlert from "@/components/InfoAlert";
import FormRules from "@/utillities/FormRules";
export default {
  name: "UpdateMGate",
  props:['mGate'],
  components: {
    InfoAlert,
  },
  data() {
    return {
      info: "",
      loading: false,
      showDismissibleAlert: false,
      valid: false,
      rules: FormRules.rules,
      title: "Update Modbus TCP Gateway",
      form: {
        ip: null,
        port: null,
        name: null,
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => /\S+/.test(v) || "Name is required",
        (v) =>
          (v && v.length <= 25 && v.length > 0) ||
          "Name must be less than 11 characters",
      ],

      ipRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+$/.test(v) || "Invalid IP",
        (v) => (v && v.length >= 8 && v.length < 16) || "IP is wrong",
      ],
      numberRules:[
            v => !!v || 'Required',
            v => /[0-9]+$/.test(v) || 'It should be a number',
            v => (v && v>0 && v%1==0)|| 'It should be a valid Port number',
            v => (v && `${v}`.length <= 4 && `${v}`.length > 0) || 'Port number must be less than 4 digits',
        ],
    };
  },
  mounted(){
    this.form['hub_id']=this.mGate.hub_id
    this.form['mgate_id']=this.mGate.mgate_id
    this.form=Object.assign({},this.mGate)
    
  },
  methods: {
    onSubmit() {
     this.$refs.MGateForm.validate();
     if(this.valid)
     {
      this.loading=true; 
      axios.post(this.$store.state.api+'updateMGates',this.form,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
      }})
      .then(response=>{
        if(response.data.status==='success'){
          this.$store.dispatch('refreshMGates')
          this.$emit('close')
      }else{
        this.info = response.data.msg;
        this.showDismissibleAlert = true;
        this.loading=false
      }})
      .catch(err=>{console.log(err)});
     }
    },

    onReset() {
      this.loading=false
      this.$emit('close');
    },
  },
};
</script>
