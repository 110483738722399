<template>
    <div>
      <v-icon @click="dialog = !dialog" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined >mdi-pencil</v-icon>
      <v-dialog v-model="dialog">
            <UpdateMGate :mGate="mGate" v-on:close="onClose"  max-width="290" />
      </v-dialog>
    </div>
  </template>
  <script>
  import UpdateMGate from '@/components/crud_components/UpdateMGate';
  export default {
      name:'UpdateMGateBottomSheet',  
      props:['mGate'],
      components:{
        UpdateMGate
      },
      
      data(){
          return {
              dialog:false,
          }
      },
      methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
            }
      },
      watch:{
        dialog(){
          console.log(this.dialog)
        }
      }
  }
  </script>
 
  