<template>
  <v-card flat :color="$store.getters.getColorPalette().mgateColor" >
   
        <v-dialog
      v-model="showDismissibleAlert"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Ooops!!</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="showDismissibleAlert = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
        <v-container>
            <v-card-title>{{mGate.name}} <v-spacer></v-spacer> <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    >            
  </v-progress-circular>
  
  <span>
    <UpdateMGateBottomSheet  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " :mGate="mGate"/>
  </span>

  
  <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "><v-btn
      class="mx-1"
     
      v-if="!loading"
      icon
      color="pink"
      
    >
     <DeleteConfirmation v-on:confirm="deleteMGate()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
      <v-icon small :dark="$store.getters.getColorPalette().isDark">
        mdi-trash-can
      </v-icon>
     </DeleteConfirmation>
    </v-btn></span></v-card-title>
        <v-divider></v-divider>
        <v-hover v-slot="{ hover }">
        <v-card-subtitle>
          <v-row >
            <v-col ><v-icon
      small
      :color="color"
    >
      {{icon}}
    </v-icon>
    <br>
             {{ $store.getters.getTextMap().health}}</v-col>
            <v-col align="center">
              <span class="display-1 font-weight-light">{{Math.floor(health)}}</span>
              <span class="subheading font-weight-light mr-1">%</span>
              
            </v-col>
          </v-row>
        
        
        <v-row v-if="hover">
          <v-col>
                <strong> {{ $store.getters.getTextMap().hub}}:</strong> {{mGate.hub_id}}
            </v-col>
            <v-col class="d-flex justify-space-between" >
               <p><strong> {{ $store.getters.getTextMap().port}}:</strong> {{mGate.port}}</p>
              </v-col>
        </v-row>
        <v-row v-if=hover>
            <v-col>
                <strong>{{ $store.getters.getTextMap().ip}}:</strong> {{mGate.ip}}
            </v-col>
            <v-col>
              <strong>{{ $store.getters.getTextMap().parity}}:</strong> {{mGate.parity}}
            </v-col>
            
        </v-row>
        <v-row v-if=hover>
            <v-col>
                <strong>{{ $store.getters.getTextMap().stop_bit}}:</strong> {{mGate.stop_bit}}
            </v-col>
            <v-col>
              <strong>{{ $store.getters.getTextMap().baud_rate}}:</strong> {{mGate.baud_rate}}
            </v-col>
            
        </v-row>
        </v-card-subtitle>
        </v-hover>
        </v-container>
    </v-card>
</template>

<script>
import axios from 'axios'
import DeleteConfirmation from '@/components/DeleteConfirmation';
import UpdateMGateBottomSheet from '@/components/modals/UpdateMGateBottomSheet.vue';
export default {
    name:'MGate',
    props:['mGate'],
    components:{
      DeleteConfirmation,
      UpdateMGateBottomSheet
    },
    data(){
        return {
            showDismissibleAlert:false,
            info:"You do not have the permission",
            loading:false,
            edit:false
        }
    },
    computed:{
        hubID(){
            return this.mGate.mgate_id.split('-')[1]
        },
        ip(){
            return this.mGate.mgate_id.split('-')[0]
        },
        health(){
          if(this.mGate && !isNaN(this.mGate.health)){
            return (Number(this.mGate.health)*100)
          }else{
            return 0
          }
        },
        icon(){
          if(this.health==100 ){
              return 'mdi-flash'
            }else if(this.health==0){
              return 'mdi-flash-off'
            }else{
              return 'mdi-flash-alert'
            }
        },
        color(){
            if(this.health==100 ){
              return 'teal'
            }else if(this.health==0){
              return 'red'
            }else{
              return 'yellow'
            }
        }
    },
    methods: {
        deleteMGate(){
            if(this.$store.state.user.user_level>this.$store.state.engineerAccessLevel){
               //this.$store.dispatch('deleteMGate',this.MGate)
               this.loading=true
               axios.post(this.$store.state.api+'deleteMGate',this.mGate,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          this.$emit('deleteMGate',this.MGate)
        this.$store.dispatch('refreshMGates')
        this.loading=false
      }else{
        this.info=response.data.msg
        this.showDismissibleAlert=true
        this.loading=false
      }})
      .catch(err=>{console.log(err)})
           }else{
               this.info="You do not have permission"
               this.showDismissibleAlert=true
               this.loading=false
           }
          
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>
<style scoped>
.v-application .teal.lighten-5{
  background-color:#B3E5FC;
}
</style>